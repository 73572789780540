import React, { useState, useEffect } from "react"
import { detectMob } from "@src/Helper"
import SEO from "@components/seo"

function Disclaimer() {
  const [id, setId] = useState("disclaimer")

  useEffect(() => {
    if (detectMob()) {
      setId("m-disclaimer")
    }
  }, [])

  return (
    <section id={id} className="section-150">
      <SEO title="Disclaimer" />
      <div className="container">
        <div style={{ marginBottom: 50 }} className="text-center">
          <div className="title">Disclaimer</div>
        </div>

        <div className="mb-5">
          <h3 className="subtitle">Website Disclaimer</h3>
          <p>
            The information provided by PT. Eksekutif Media Utama (“Company”,
            “we”, “our”, “us”) on www.executivemafia.com (the “Site”) is for
            general informational purposes only. All information on the Site is
            provided in good faith, however we make no representation or
            warranty of any kind, express or implied, regarding the accuracy,
            adequacy, validity, reliability, availability, or completeness of
            any information on the Site.
          </p>
          <p>
            UNDER NO CIRCUMSTANCE SHALL WE HAVE ANY LIABILITY TO YOU FOR ANY
            LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF THE
            SITE OR RELIANCE ON ANY INFORMATION PROVIDED ON THE SITE. YOUR USE
            OF THE SITE AND YOUR RELIANCE ON ANY INFORMATION ON THE SITE IS
            SOLELY AT YOUR OWN RISK.
          </p>
        </div>
        <div className="mb-5">
          <h3 className="subtitle">Earning Disclaimer</h3>
          <p>
            Any income or earnings statements are estimates of income potential
            only, and there is no assurance that your earnings will match the
            figures presented, which are given as examples.
          </p>
          <p>
            All products and services of our company are for educational and
            informational purposes only. Thus, nothing on our website or
            otherwise disseminated by Executive Mafia in conjunction with it
            should be taken as medical, legal, accounting or other such advice.
            Use caution and seek the advice of qualified professionals. Check
            with a qualified accountant, lawyer or professional advisor, before
            acting on this or any information.
          </p>
          <p>
            Your use of the website, participation in Member or Affiliate
            programs confirm your consent and agreement that PT. Eksekutif Media
            Utama is not responsible for the success or failure of your business
            decisions relating to any information presented by our company, or
            our company programs, products and/or services. Information related
            to income or financial success provided on any website owned or
            operated by the Seller is simply information and is not directly
            related to any MLM or business opportunity of any kind. We at PT.
            Eksekutif Media Utama are not responsible for any errors or
            commissions contained in any disseminated material and are not
            liable for any loss incurred as a result of using the material in
            any way. The intent is merely to provide useful information,
            products, and services, some of which we may be compensated for.
          </p>
          <p>
            AS WITH ANY BUSINESS ENDEAVOR, WE DO NOT WARRANT AN INHERENT RISK OF
            LOSS OF CAPITAL AND THERE IS NO GUARANTEE THAT YOU WILL EARN ANY
            MONEY. PLEASE READ ALL AGREEMENTS, NOTICES AND DISCLAIMERS BEFORE
            PURCHASING ANYTHING. AFFILIATE PROGRAMS WITH PRODUCTS OF DIGITAL
            NATURE AND EVENT SALES HAVE UNKNOWN RISKS INVOLVED, AND ARE NOT
            SUITABLE FOR EVERYONE. MAKING DECISIONS BASED ON ANY INFORMATION
            PRESENTED IN OUR PROGRAMS, PRODUCTS, SERVICES OR ON OUR WEBSITE,
            SHOULD BE DONE ONLY WITH THE KNOWLEDGE THAT YOU COULD EXPERIENCE
            SIGNIFICANT LOSSES, OR MAKE NO MONEY AT ALL. ONLY RISK CAPITAL
            SHOULD BE USED.
          </p>
        </div>
        <div className="mb-5">
          <h3 className="subtitle">External Links Disclaimer</h3>
          <p>
            The Site may contain (or you may be sent through the Site) links to
            other websites or content belonging to or originating from
            third-parties or links to websites and features. Such external links
            are not investigated, monitored, or checked for accuracy, adequacy,
            validity, reliability, availability or completeness by us.
          </p>
          <p>
            WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR
            THE ACCURACY OR RELIABILITY OF ANY INFORMATION OFFERED BY
            THIRD-PARTY WEBSITES LINKED THROUGH THE SITE OR ANY WEBSITE OR
            FEATURE LINKED IN ANY BANNER OR OTHER ADVERTISING. WE WILL NOT BE A
            PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY TRANSACTION
            BETWEEN YOU AND THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES.
          </p>
        </div>
        <div className="mb-5">
          <h3 className="subtitle">Testimonials Disclaimer</h3>
          <p>
            The Site may contain testimonials by users of our products and/or
            services. These testimonials reflect the real-life experiences and
            opinions of such users. However, the experiences are personal to
            those particular users, and may not necessarily be representative of
            all users of our products and/or services. We do not claim, and you
            should not assume that all users will have the same experiences.
          </p>
          <p>
            <b>YOUR INDIVIDUAL RESULTS MAY VARY.</b>
          </p>
          <p>
            The testimonials on the Site are submitted in various forms such as
            text, audio and/or video, and are reviewed by us before being
            posted. They appear on the Site verbatim as given by the users,
            except for the correction of grammar or typing errors. Some
            testimonials may have been shortened for the sake of brevity, where
            the full testimonial contained extraneous information not relevant
            to the general public.
          </p>
          <p>
            The views and opinions contained in the testimonials belong solely
            to the individual user and do not reflect our views and opinions.
          </p>
        </div>
        <div className="mb-5">
          <h3 className="subtitle">Errors and Omissions Disclaimer</h3>
          <p>
            While we have made every attempt to ensure that the information
            contained in this site has been obtained from reliable sources, PT.
            Eksekutif Media Utama is not responsible for any errors or omissions
            or for the results obtained from the use of this information. All
            information in this site is provided “as is”, with no guarantee of
            completeness, accuracy, timeliness or of the results obtained from
            the use of this information, and without warranty of any kind,
            express or implied, including, but not limited to warranties of
            performance, merchantability, and fitness for a particular purpose.
          </p>
          <p>
            In no event will PT. Eksekutif Media Utama, its related partnerships
            or corporations, or the partners, agents or employees thereof be
            liable to you or anyone else for any decision made or action taken
            in reliance on the information in this Site or for any
            consequential, special or similar damages, even if advised of the
            possibility of such damages.
          </p>
        </div>
        <div className="mb-5">
          <h3 className="subtitle">Logos and Trademarks Disclaimer</h3>
          <p>
            All logos and trademarks of third parties referenced on
            www.executivemafia.com are the trademarks and logos of their
            respective owners. Any inclusion of such trademarks or logos does
            not imply or constitute any approval, endorsement or sponsorship of
            PT. Eksekutif Media Utama by such owners.
          </p>
          <p>
            NOTICE OF CHANGE: The contents of this page may change over time. We
            reserve the right to change, alter and update the content as
            necessary.
          </p>
        </div>
        <div className="mb-5">
          <h3 className="subtitle">Contact Us</h3>
          <p>
            INQUIRIES/COMMENTS/CONCERNS: If you have any questions about the
            contents of this page, please connect with our Customer Care team at{" "}
            <a href="mailto:support@executivemafia.com">
              support@executivemafia.com
            </a>{" "}
            or by mailing us to:
          </p>
          <p>
            PT. EKSEKUTIF MEDIA UTAMA
            <br />
            21, JALAN MELATI, KOMPLEK CEMARA ASRI
            <br />
            MEDAN CITY, NORTH SUMATRA 20371
            <br />
            INDONESIA
          </p>
        </div>
      </div>
    </section>
  )
}

export default Disclaimer
